import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import CustomOrderListRenderData from "./CustomOrderListRenderData";
import EmptyData from "../EmptyData/EmptyData";

function CustomOrderListTitle({
  data,
  amountSort,
  productInformationShow,
  orderStatusChange,
  hideMore,
  handleScroll,
  commentsContainerRef,
  setIsModalOpen,
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: 1,
        borderRadius: "8px",
        border: Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: 1,
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0.8rem",
        }}
      >
        <div
          style={{ width: productInformationShow ? "15%" : "20%" }}
          className="contentName"
        >
          Order ID
        </div>
        <div
          style={{ width: productInformationShow ? "22%" : "35%" }}
          className="contentName"
        >
          Customers
        </div>
        {productInformationShow && (
          <div style={{ width: "25%" }} className="contentName">
            Product information
          </div>
        )}
        <div
          style={{ width: productInformationShow ? "15%" : "22%" }}
          className="contentName"
        >
          Date & Time
        </div>
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            width: "10%",
            cursor: "pointer",
          }}
          onClick={amountSort}
          className="contentName"
        >
          Amount
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div style={{ width: "10%" }} className="contentName">
          Status
        </div>
        <div style={{ width: "3%" }}></div>
        <div style={{ width: "3px" }}></div>
      </div>

      <div
        ref={commentsContainerRef}
        onScroll={handleScroll}
        style={{ maxHeight: "500px" }}
        className="scrollable-container"
      >
        <CustomOrderListRenderData
          data={data}
          setIsModalOpen={setIsModalOpen}
          hideMore={hideMore}
          orderStatusChange={orderStatusChange}
          productInformationShow={productInformationShow}
        />
      </div>
    </div>
  );
}

export default CustomOrderListTitle;
